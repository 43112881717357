<template>
  <div class="flex flex-col h-full">
    <div v-if="displayItems?.length > 0" class="flex-grow flex flex-col">
      <div class="my-8 flex-grow">
        <el-table
          :data="displayItems"
          class="rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @sort-change="sortBarcode"
          @current-change="onEditBarcode"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :selectable="selectableTargetRow" :min-width="5" />
          <el-table-column prop="id" :label="$t('barcode.id')" :min-width="20" sortable />
          <el-table-column :label="$t('projects.barcodeType')" :min-width="20">
            <template v-slot="scope">
              <div class="flex" v-if="getBarcodeType(scope.row.barcodeType)">
                <div class="w-8 h-8 barcode-icon-container flex item-centers">
                  <BarcodeIcon :barcodeType="getBarcodeType(scope.row.barcodeType)" :isShowLabel="false" />
                </div>
                <div class="flex-grow flex items-center ml-4">{{ getBarcodeType(scope.row.barcodeType)?.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('barcode.currentTrackpoint')" :min-width="20">
            <template v-slot="scope">{{ getTrackPointName(scope.row.currentTrackPointKey) }}</template>
          </el-table-column>
          <el-table-column prop="audit.createdDateTime" :label="$t('barcode.createdDate')" :min-width="20" sortable>
            <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit?.createdDateTime) }}</template>
          </el-table-column>
          <el-table-column prop="audit.updatedDateTime" :label="$t('barcode.updatedDate')" :min-width="20" sortable>
            <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit?.updatedDateTime) }}</template>
          </el-table-column>
          <el-table-column prop="isActive" :label="$t('status')" :min-width="10">
            <template v-slot="scope">{{ scope.row.isActive ? $t('active') : $t('inactive') }}</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { TABLE_SORT_TYPE } from '@/utils/constants'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeList,
  IBarcodeSearchForm,
  ITableSortData,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, BarcodeIcon },
  emits: ['dialog:close', 'update:selectedBCs'],
  name: 'BarcodeChildren',
})
export default class BarcodeChildren extends Mixins(DataTableMixin) {
  dataSearch = {} as IBarcodeSearchForm

  handleSelectionChange(selections: IBarcode[]) {
    this.$emit(
      'update:selectedBCs',
      selections.map((bc) => bc.id)
    )
  }

  selectableTargetRow(row: IBarcode) {
    return true
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.barcode?.projectDetail?.trackPoints
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.$store.state.barcode?.projectDetail?.barcodeTypes?.[barcodeTypeCode]
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  onEditBarcode(barcode: IBarcodeList) {
    const id = barcode.id
    this.$router.push({
      name: 'barcodeView',
      params: { id },
    })

    this.$emit('dialog:close')
  }

  @Watch('$store.state.barcode?.barcodeChildren', { deep: true })
  barcodeChildrenChanged() {
    this.displayItems = this.$store.state.barcode?.barcodeChildren
  }

  created() {
    this.displayItems = this.$store.state.barcode?.barcodeChildren
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order]
    this.redirectIndex()
    this.sortDisplayItems()
  }
}
</script>
