
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import { FETCH_BARCODE, FETCH_BARCODE_CHILDREN, SET_PAGE_NOT_FOUND } from '@/store/actions'
import { getBarcodeList, getCountBarcodeList, pairBarcode } from '@/utils/api'
import { removeIdxList, removeIf } from '@/utils/arrayUtils'
import errorHandler from '@/utils/errorHandler'
import { formatSearchConditionBlocks, initDataSearch } from '@/utils/helpers'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeSearch,
  IBarcodeSearchForm,
  ISearchConditionBlock,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, ProvideReactive } from 'vue-property-decorator'
@Options({
  components: { SearchForm, BarcodeIcon },
  name: 'BarcodePairingDialog',
})
export default class BarcodePairingDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  @Prop({ type: String }) readonly barcodeId?: string
  @ProvideReactive() projectVersion = 0

  dataSearch = {} as IBarcodeSearchForm

  loading = false
  currentPageNum = 1
  searchResultBCs = []
  searchResultCount = 0

  selectedPairingBCs = [] as IBarcode[]

  selectableSearchRow(row: IBarcode) {
    return (
      row.isActivated &&
      !row.isDeactivated &&
      row.id !== this.barcode.id &&
      !this.selectedPairingBCs.map((bc) => bc.id).includes(row.id)
    )
  }

  selectableTargetRow(row: IBarcode) {
    return true
  }

  get getBarcodePerPageSize() {
    return Math.round((window.innerHeight - 100) / 100)
  }

  get project() {
    return this.$store.state.barcode?.projectDetail
  }

  previousSearchData = {} as IBarcodeSearch
  previousSearchCount = 0

  get barcode() {
    return this.$store.state.barcode?.barcode
  }

  checkedBCs = [] as IBarcode[]
  handleSearchSelectionChange(selectedSearchBCs: IBarcode[]) {
    this.checkedBCs = selectedSearchBCs
  }

  handleTargetSelectionChange(selectedSearchBCs: IBarcode[]) {
    this.checkedTargetBCs = selectedSearchBCs
  }

  onAddTargetList() {
    const newTargets = [] as IBarcode[]
    const addToTargetList = async () => {
      try {
        this.loading = true
        await pairBarcode({
          isDryRun: true,
          parentBarcodeId: this.barcode.id,
          childrenBarcodeIds: newTargets.map((bc) => bc.id),
          forcePairing: true,
        })
        this.selectedPairingBCs.push(...newTargets)
      } catch (err) {
        errorHandler(err)
      } finally {
        this.loading = false
      }
    }

    const removeList: number[] = []
    this.checkedBCs.forEach((bc, idx) => {
      // local filter
      const found = this.selectedPairingBCs.find((selectedBC) => bc.id === selectedBC.id)
      if (!found) newTargets.push(bc)

      removeIf<IBarcode>(this.searchResultBCs, (item) => item.id === bc.id)
      removeList.push(idx)
    })
    // remove check in search list
    removeIdxList(this.checkedBCs, removeList)

    // Do pairing new targets
    addToTargetList()
  }

  checkedTargetBCs = [] as IBarcode[]
  onSelectedTargetBC(checked: boolean, bc: IBarcode) {
    if (checked) {
      this.checkedTargetBCs.push(bc)
    } else {
      this.checkedTargetBCs.splice(this.checkedTargetBCs.indexOf(bc), 1)
    }
  }

  onRemoveTargetList() {
    this.checkedTargetBCs.forEach((bc, idx) => {
      removeIf<IBarcode>(this.selectedPairingBCs, (item) => bc.id === item.id)
      this.checkedTargetBCs.splice(1, idx)
    })
  }

  async performSearch() {
    this.checkedBCs = []
    this.dataSearch.skip = 0
    this.dataSearch.projectCode = this.project.code
    this.dataSearch.version = this.project.version

    this.fetchSearchBCs()
  }

  clearSearchForm() {
    this.dataSearch = initDataSearch()
  }

  get path() {
    return this.$route.query?.path
  }

  async performPairing() {
    try {
      this.loading = true
      await pairBarcode({
        isDryRun: false,
        parentBarcodeId: this.barcode.id,
        childrenBarcodeIds: this.selectedPairingBCs.map((bc) => bc.id),
        forcePairing: true,
      })
      openMessage(this.$t('barcode.barcode_paired'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this._isShowDialog = false
      this.selectedPairingBCs = []
      this.checkedBCs = []
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.barcode.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))
      this.loading = false
    }
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.$store.state.barcode?.projectDetail?.barcodeTypes?.[barcodeTypeCode]
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.barcode?.projectDetail?.trackPoints
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  async fetchSearchBCs() {
    try {
      this.loading = true
      const data = this.dataSearch

      // format dataSearch
      const condBlocks = [] as ISearchConditionBlock[]
      data.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))
      const dataSearch = {
        condition: {
          ...data,
          searchConditionBlocks: formatSearchConditionBlocks(condBlocks),
        },
      } as IBarcodeSearch

      try {
        this.searchResultBCs = await getBarcodeList(dataSearch)
        const isPerformCount =
          isEmpty(this.previousSearchData) ||
          !isEqual(omit(cloneDeep(this.previousSearchData), 'skip'), omit(data, 'skip'))
        if (isPerformCount) {
          getCountBarcodeList(dataSearch)
            .then((res) => {
              this.searchResultCount = res?.count ?? 0
              this.previousSearchCount = this.searchResultCount
            })
            .catch((err) => console.error('error', err))
        } else {
          this.searchResultCount = this.previousSearchCount ?? dataSearch.condition.count
        }
      } catch (e) {
        this.searchResultBCs = []
        this.searchResultCount = 0
      }
    } catch (err) {
      errorHandler(err)
    } finally {
      this.loading = false
    }
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    // this.currentSkip = skipNum

    // if (this.isTriggerPageChanging) {
    this.currentPageNum = pageNum
    this.dataSearch.skip = (Number(pageNum) - 1) * Number(this.dataSearch.count)
    await this.fetchSearchBCs()
  }

  sortBarcode() {
    // Sort
  }

  mounted() {
    this.dataSearch = initDataSearch()
    this.projectVersion = this.project.version
  }
}
