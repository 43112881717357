<template>
  <div v-loading.fullscreen="loading" class="flex flex-col h-full">
    <div>
      <div class="full-page-width fixed-header-container">
        <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
          <div class="flex-grow items-center flex header-container__left">
            <PageHeader :title="$t('barcode.search')" :isHideRefresh="true" />
          </div>
          <div v-if="referenceAvailable && !isLoggedAsClient" class="flex header-container__right mr-3">
            <el-button @click="onRefreshReferenceFieldClick" type="primary" size="medium">
              <i class="el-icon-refresh"></i>
            </el-button>
          </div>
          <div
            v-if="barcodeReportTemplates && barcodeReportTemplates.length > 0 && !isLoggedAsClient"
            class="flex header-container__right mr-3"
          >
            <el-popover placement="bottom-end" :width="200" trigger="hover" popper-class="popover-barcode-list">
              <template #reference>
                <el-button type="primary" size="medium" class="text-white focus:text-white" :disabled="loading">
                  {{ $t('barcode_type.report_output') }}
                </el-button>
              </template>
              <div
                v-for="reportTemplate in barcodeReportTemplates"
                :key="reportTemplate.code"
                :class="['cursor-pointer text-center', 'text-black', 'my-2']"
                @click="requestReport(reportTemplate.code || '')"
              >
                {{ reportTemplate.buttonLabel }}
              </div>
            </el-popover>
          </div>
          <div v-if="externalWebAPIAvailable && !isLoggedAsClient" class="flex header-container__right">
            <el-button @click="InvokeExternalWebAPI" type="primary" size="medium">
              {{ getExternalWebAPIButtonLabel }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="pt-12">
        <div class="barcode-detail-container">
          <div class="barcode-detail-container__left">
            <div class="relative mt-8 pt-6">
              <div class="absolute top-0 right-0 w-36" v-if="dataUrl">
                <img :src="dataUrl" class="w-full" />
              </div>
              <div class="">
                <div class="font-medium h-11 my-4px flex items-center">
                  {{ $t('barcode.overview') }}
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <BarcodeRow :labelWidth="40" :label="'ID'" :value="barcode.id" :isCoppy="true" />
                  </div>
                  <div class="w-full--50-percent-40 px-2px">
                    <BarcodeRow
                      :labelWidth="24"
                      :label="$t('status')"
                      :value="barcode.isActive ? $t('active') : $t('inactive')"
                    />
                  </div>
                  <div class="w-40 px-2px"></div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-full--40 px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-40`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ 'URL' }}
                        </div>
                      </div>
                      <div class="px-2px w-full--40">
                        <div class="flex h-10 bg-white items-center px-2">
                          <template v-if="barcodeURL">
                            <a class="url-underline text-black w-full truncate leading-normal" :href="barcodeURL">
                              {{ barcodeURL }}
                            </a>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-40 px-2px"></div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <BarcodeRow :labelWidth="40" :label="$t('projects.projectCode')" :value="project.code" />
                  </div>
                  <div class="w-full--50-percent-40 px-2px">
                    <BarcodeRow :labelWidth="32" :label="$t('projects.projectName')" :value="project.name" />
                  </div>
                  <div class="w-40 px-2px">
                    <BarcodeRow :labelWidth="28" :label="$t('projects.version')" :value="String(project.version)" />
                  </div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-40`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ $t('barcode.barcode') }}
                        </div>
                      </div>
                      <div class="w-full--40 px-2px">
                        <div class="flex h-10 bg-white text-black items-center px-2">
                          <div class="flex" v-if="barcodeType">
                            <div class="w-8 h-8 barcode-icon-container flex item-centers">
                              <BarcodeIcon
                                :barcodeType="barcodeType"
                                :isShowLabel="false"
                                :key="`detail-${barcode.id}`"
                                :isPointer="false"
                              />
                            </div>
                            <div class="flex-grow flex items-center ml-4">{{ barcodeType?.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full--50-percent px-2px">
                    <BarcodeRow :labelWidth="80" :label="$t('barcode.currentTrackpoint')" :value="currentTrackPoint" />
                  </div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-40`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ $t('barcode.parentBarcode') }}
                        </div>
                      </div>
                      <div class="flex-grow px-2px">
                        <div class="flex h-10 bg-white text-black items-center px-2 truncate leading-normal">
                          <template v-if="barcode.hasParent">
                            {{ $t('yes') }}
                            (<a class="underline" @click="goToBarcode(barcode.parentId)">
                              {{ $t('barcode.goToParentBarcode') }} </a
                            >)
                          </template>
                          <template v-else>
                            {{ $t('no') }}
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full--50-percent px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-40`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ $t('barcode.childrenBarcode') }}
                        </div>
                      </div>
                      <div class="flex-grow px-2px">
                        <div class="flex h-10 bg-white text-black items-center px-2 truncate leading-normal">
                          <div class="flex-grow">
                            <template v-if="barcode.childrenCount > 0">
                              {{ $t('yes') }}
                            </template>
                            <template v-else>
                              {{ $t('no') }}
                            </template>
                          </div>
                          <el-button
                            v-if="barcode.childrenCount > 0"
                            type="default"
                            size="small"
                            @click="openChildrenBarcode"
                            class="bg-green-dark-min text-white focus:text-white rounded-md "
                          >
                            {{ $t('barcode.display_children_barcode_list') }}
                          </el-button>

                          <el-button
                            v-if="isAllowPairing"
                            type="default"
                            size="small"
                            @click="onOpenPairingDialog"
                            class="bg-blue-dark-min text-white focus:text-white rounded-md "
                          >
                            {{ $t('barcode.pairing_settings') }}
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-40`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ $t('barcode.recycled_barcode') }}
                        </div>
                      </div>
                      <div class="flex-grow px-2px">
                        <div class="flex h-10 bg-white text-black items-center px-2 truncate leading-normal">
                          <template v-if="isAvailableRecycledBarcode">
                            {{ $t('yes') }}
                            (<a class="underline" @click="goToBarcode(barcode.rootId)">
                              {{ $t('barcode.move_to_recycled_barcode') }} </a
                            >)
                          </template>
                          <template v-else>
                            {{ $t('no') }}
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isLoggedAsClient" class="w-full--50-percent px-2px">
                    <div class="flex mx--2">
                      <div class="px-2px" :class="`w-52`">
                        <div class="h-10 text-gray-500 bg-gray-100 flex items-center px-2">
                          {{ $t('barcode.recycled_barcode_history') }}
                        </div>
                      </div>
                      <div class="flex-grow px-2px">
                        <div class="flex h-10 bg-white text-black items-center px-2 truncate leading-normal">
                          <template v-if="isLoadedRecycleHistory">
                            <template v-if="recycleHistoryBarcodes.length > 0">
                              {{ $t('yes') }}
                              (<a class="underline" @click="openBarcodeHistoryDialog()">
                                {{ $t('barcode.open_barcode_history_list') }} </a
                              >)
                            </template>
                            <template v-else> {{ $t('no') }} </template>
                          </template>
                          <template v-else><div v-loading="true"></div></template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-6">
              <div class="">
                <div class="font-medium h-11 my-4px flex items-center">
                  {{ $t('barcode.createUpdateInformation') }}
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <BarcodeRow
                      :labelWidth="40"
                      :label="$t('barcode.createdUser')"
                      :value="displayUsername(barcode?.audit?.createdUserId)"
                    />
                  </div>
                  <div class="w-full--50-percent px-2px">
                    <BarcodeRow
                      :labelWidth="40"
                      :label="$t('barcode.createdDate')"
                      :value="displayDateTimeLocal(barcode?.audit?.createdDateTime)"
                    />
                  </div>
                </div>
                <div class="flex mx--2 my-4px">
                  <div class="w-1/2 px-2px">
                    <BarcodeRow
                      :labelWidth="40"
                      :label="$t('barcode.updatedUser')"
                      :value="displayUsername(barcode?.audit?.updatedUserId)"
                    />
                  </div>
                  <div class="w-full--50-percent px-2px">
                    <BarcodeRow
                      :labelWidth="40"
                      :label="$t('barcode.updatedDate')"
                      :value="displayDateTimeLocal(barcode?.audit?.updatedDateTime)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-6">
              <div class="">
                <div class="font-medium h-11 my-4px flex items-center">
                  {{ $t('barcode.activationInformation') }}
                </div>
                <div>
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.trackingNumber')"
                        :value="activationData?.trackingNumber"
                        :isCoppy="true"
                      />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.externalId')"
                        :value="activationData?.externalId"
                        :isCoppy="true"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.originPostalCode')"
                        :value="activationData?.origin?.postalCode"
                      />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.originPrefectures')"
                        :value="activationData?.origin?.prefecture"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('barcode.originAddress')"
                        :value="`${activationData?.origin?.address1 || ''} ${activationData?.origin?.country || ''}`"
                        :isAddressLocation="!!activationData?.origin?.address1"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('barcode.originAddressSecond')"
                        :value="`${activationData?.origin?.address2 || ''} ${activationData?.origin?.country || ''}`"
                        :isAddressLocation="!!activationData?.origin?.address2"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.originName')"
                        :value="activationData?.origin?.companyName"
                      />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.originPhoneNumber')"
                        :value="activationData?.origin?.phoneNumber"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.destinationPostalCode')"
                        :value="activationData?.destination?.postalCode"
                      />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.destinationPrefectures')"
                        :value="activationData?.destination?.prefecture"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('barcode.destinationAddress')"
                        :value="
                          `${activationData?.destination?.address1 || ''} ${activationData?.destination?.country || ''}`
                        "
                        :isAddressLocation="!!activationData?.destination?.address1"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('barcode.destinationAddressSecond')"
                        :value="
                          `${activationData?.destination?.address2 || ''} ${activationData?.destination?.country || ''}`
                        "
                        :isAddressLocation="!!activationData?.destination?.address2"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.destinationName')"
                        :value="activationData?.destination?.companyName"
                      />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow
                        :labelWidth="40"
                        :label="$t('barcode.destinationPhoneNumber')"
                        :value="activationData?.destination?.phoneNumber"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="flex mx--2 my-4px">
                    <div class="w-1/2 px-2px">
                      <BarcodeRow :labelWidth="40" :label="$t('product.product_code')" :value="product?.code" />
                    </div>
                    <div class="w-full--50-percent px-2px">
                      <BarcodeRow :labelWidth="40" :label="$t('Name')" :value="product?.name" />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('product.material')"
                        :value="product?.material"
                      />
                    </div>
                  </div>
                  <div class="flex mx--2 my-4px">
                    <div class="px-2px w-full">
                      <BarcodeRow
                        :labelWidth="40"
                        :maxLength="80"
                        :label="$t('product.luggage_size')"
                        :value="formatDimension(activationData?.dimension)"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <BarcodeCustomField
                    :barcodeCustomFields="activationData?.customFields"
                    :projectCustomFields="barcodeType?.activationFields?.customFields"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="barcode-detail-container__right">
            <div class="mt-8 pt-6 pb-16 2xl:px-4">
              <div class="">
                <div class="font-medium h-11 my-4px flex items-center">
                  {{ $t('barcode.trackingInformation') }}
                </div>
                <div class="max-w-xl m-auto" v-if="hasFirstLinkingBarcodes || hasTrackingData">
                  <div class="border-2 border-gray-400 border-solid px-6 pt-8 pb-6 rounded-xl">
                    <TraceView
                      ref="tracingComponent"
                      v-if="barcode && project"
                      :project="project"
                      :barcode="barcode"
                      :messages="messages"
                      :isAction="false"
                      @barcode:invokeConcile="invokeConcile"
                      @barcode:getLedgerStatus="getLedgerStatus"
                      @web:redirect="redirectTo"
                    />
                  </div>
                </div>
                <template v-else>
                  <ItemNotFound :content="$t('empty')" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="isShowChildrenDialog"
    custom-class="dialog-children-barcodes"
    :width="'70%'"
    :destroy-on-close="true"
    @close="closeChildrenDialog"
  >
    <template #title>
      <div class="flex justify-between mr-2">
        <span class="self-center"> {{ $t('barcode.barcode_children_list') }}</span>
        <div class="flex justtify-between">
          <el-button
            @click="downloadCSV('parentId')"
            type="default"
            class="focus:text-white rounded-md mr-8  hover:bg-green-500 hover:text-white el-process-btn"
            size="mini"
          >
            <div class="flex flex-col items-center">
              <span class="ml-1">{{ $t('barcodeImport.csvDownload') }}</span>
              <IconDownload />
            </div>
          </el-button>
        </div>
      </div>
    </template>
    <div class="flex flex-col items-center content-center">
      <div class="w-full">
        <BarcodeChildren @dialog:close="closeChildrenDialog" @update:selectedBCs="onUpdateUnpairingBCs" />
      </div>

      <el-button
        type="default"
        class="bg-blue-dark-min text-white focus:text-white rounded-md"
        @click="performUnpairing"
      >
        {{ $t('barcode.unpairing') }}
      </el-button>
    </div>
  </el-dialog>

  <BarcodeHistoryDialog
    @onHistoryDownload="downloadCSV"
    @onHistorySkipItem="onHistorySkipItem"
    :barcodes="recycleHistoryBarcodes"
    :recycleCount="recycleCount"
    v-model:isShowDialog="isShowHistoryBarcodeDialog"
  />

  <BarcodePairingDialog v-model:isShowDialog="isShowPairingBCDialog" />
</template>

<script lang="ts">
import BarcodeChildren from '@/components/barcode/BarcodeChildren.vue'
import BarcodeCustomField from '@/components/barcode/BarcodeCustomField.vue'
import BarcodeRow from '@/components/barcode/BarcodeRow.vue'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconUpload from '@/components/svg/IconUpload.vue'
import {
  FETCH_BARCODE,
  FETCH_BARCODE_CHILDREN,
  FETCH_USER_LIST,
  SET_HAVE_NEW_EXPORT_RECORD,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import {
  exportBarCode,
  getBarcodeLedgerStatus,
  getRecycleHistory,
  invokeExternalAPI,
  reconcileBarcode,
  requestBarcodeReport,
  unpairBarcode,
  updateReferenceFieldBarcode,
} from '@/utils/api'
import { LOGICAL_OPERATOR_VALUE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { displayDateTimeLocal, displayMomentAtParam } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import QRCode from 'qrcode'
import TraceView from 'smartbarcode-web-core/src/lib-components/tracing.vue'
import { ECustomFieldType, EExportType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeReportTemplate,
  IDimension,
  IRedirectData,
  ISelectData,
  ITracingMessage,
  ITrackingData,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import BarcodeHistoryDialog from './BarcodeHistoryDialog.vue'
import BarcodePairingDialog from './BarcodePairingDialog.vue'
@Options({
  components: {
    BarcodeRow,
    BarcodeIcon,
    BarcodeCustomField,
    BarcodePairingDialog,
    PageHeader,
    TraceView,
    BarcodeChildren,
    ItemNotFound,
    BarcodeHistoryDialog,
    IconDownload,
    IconUpload,
  },
  name: 'BarcodeDetail',
})
export default class BarcodeDetail extends Vue {
  isShowChildrenDialog = false
  isShowHistoryBarcodeDialog = false
  dataUrl = ''
  recycleHistoryBarcodes = [] as IBarcode[]
  loading = false
  isLoadedRecycleHistory = false
  operationTypes = ''
  recycleHistorySkipItem = 0

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get recycleCount() {
    return this.barcode.recycleCount || 0
  }

  openBarcodeHistoryDialog() {
    this.isShowHistoryBarcodeDialog = true
  }

  unpairingSelectedBCs: string[] = []
  onUpdateUnpairingBCs(bcList: string[]) {
    this.unpairingSelectedBCs = bcList
  }

  async performUnpairing() {
    try {
      this.loading = true
      const req = {
        isDryRun: true,
        parentBarcodeId: this.barcode.id,
        isUnpairAll: false,
        unpairChildrenIds: this.unpairingSelectedBCs,
      }
      await unpairBarcode(req)

      req.isDryRun = false
      await unpairBarcode(req)
      openMessage(this.$t('barcode.barcode_unpaired'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this.unpairingSelectedBCs = []
      this.isShowChildrenDialog = false
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      this.loading = true
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))

      this.loading = false
    }
  }

  isShowPairingBCDialog = false
  onOpenPairingDialog() {
    this.isShowPairingBCDialog = true
  }

  get isAllowPairing() {
    return (
      !this.barcode?.isDeactivated &&
      (this.barcodeType?.pairing?.[this.barcode.currentTrackpointKey ?? 0]?.isAvailable ?? false)
    )
  }

  get messages(): ITracingMessage {
    return {
      trackingNumber: this.$t('tracking_number'),
      undoTrackingData: this.$t('barcode.undo_tracking_data'),
      noTrackingHistory: this.$t('barcode.no_tracking_history'),
      packageImages: this.$t('barcode.packageImages', { number: '{0}' }),
      confirmUndoTrackingData: this.$t('barcode.confirm_undo_tracking_data'),
      cancel: this.$t('cancel'),
      ok: this.$t('ok'),
      trackedDatetime: this.$t('barcode.tracked_datetime'),
      trackedUsername: this.$t('barcode.tracked_username'),
      sign: this.$t('sign'),
    } as ITracingMessage
  }

  get getExternalWebAPIButtonLabel() {
    const barcodeType: IBarcodeDefinitionType = this.barcodeType
    return barcodeType?.externalWebAPIIntegration?.buttonLabel || this.$t('export_pdf')
  }

  get externalWebAPIAvailable() {
    return !!this.barcodeType?.externalWebAPIIntegration
  }

  get referenceAvailable() {
    const bc = cloneDeep(this.barcode?.activationData?.customFields) || {}
    const isActivationDataReferenceAvailable = Object.keys(bc).some((val) => {
      if (bc?.[val]?.fieldType) return bc[val].fieldType === ECustomFieldType.REFERENCE
    })

    const trackingData = this.barcode.trackingData as ITrackingData[]
    const isTrackingDataReferenceAvailable = trackingData?.some((td) => {
      const isTrackingDataReferenceAvailable = Object.keys(td?.customFields || {}).some((key) => {
        if (td?.customFields?.[key]?.fieldType) return td?.customFields?.[key]?.fieldType === ECustomFieldType.REFERENCE
      })
      return isTrackingDataReferenceAvailable
    })

    return isActivationDataReferenceAvailable || isTrackingDataReferenceAvailable
  }

  get barcodeReportTemplates() {
    return this.barcodeType?.barcodeReportTemplates ?? ([] as IBarcodeReportTemplate[])
  }

  get id() {
    return this.$route.name === 'barcodeView' ? this.$route.params.id : ''
  }

  get path() {
    return this.$route.query?.path
  }

  get isAvailableRecycledBarcode() {
    return this.barcode.id !== this.barcode.rootId
  }

  get project() {
    return this.$store.state.barcode?.projectDetail
  }

  get barcode() {
    return this.$store.state.barcode?.barcode
  }

  get product() {
    return this.$store.state.barcode?.product
  }

  get barcodeType(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes?.[this.barcode.barcodeType]
  }

  get projectBarcodeTypeKey() {
    return this.project?.barcodeTypes
  }

  get activationData() {
    return this.$store.state.barcode?.barcode?.activationData
  }

  get currentTrackPoint() {
    return this.project?.trackPoints?.[this.barcode?.currentTrackPointKey]?.name
  }

  get barcodeURL() {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    return this.barcode ? `${appHost}/${this.$store.getters.projectParam}/qrcode/${this.barcode.id}` : ''
  }

  get hasFirstLinkingBarcodes(): boolean {
    return !isEmpty(this.barcode.activationData?.linkedBarcodes)
  }

  get hasTrackingData() {
    return !isEmpty(this.barcode?.trackingData)
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  async onRefreshReferenceFieldClick() {
    try {
      this.loading = true
      await updateReferenceFieldBarcode(this.barcode.id)
      await this.reloadData()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async openChildrenBarcode() {
    if (this.barcode.id !== this.$store.state.barcode?.parentId) {
      this.loading = true
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))
    }
    this.isShowChildrenDialog = true
  }

  get getUsers(): ISelectData[] {
    return this.$store.state.profile?.userList
  }

  async InvokeExternalWebAPI() {
    try {
      this.loading = true
      const response = await invokeExternalAPI(this.barcode?.id || '')
      if (!response) return

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${displayMomentAtParam(moment())}.pdf`
      document.body.appendChild(link)
      link.click()
      openMessage(this.$t('barcode_status.completed'), 'success')
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async requestReport(code: string) {
    try {
      this.loading = true
      if (this.barcode?.id) {
        await requestBarcodeReport(this.barcode.id, code)
      }

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async invokeConcile(index: number) {
    try {
      const { trackingData } = await reconcileBarcode(this.barcode?.id, index)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[
        index
      ].lastLedgerCertifiedDateTime = lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  async getLedgerStatus(index: number) {
    try {
      const { trackingData } = await getBarcodeLedgerStatus(this.barcode?.id, index)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLoading = false

      this.$refs.tracingComponent.trackingDataItemsLoading[
        index
      ].lastLedgerCertifiedDateTime = lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  closeChildrenDialog() {
    this.isShowChildrenDialog = false
  }

  formatDimension(dimension: IDimension) {
    if (dimension) {
      const arrString = []
      if (dimension.width) arrString.push(`${this.$t('width')} ${dimension.width} cm `)
      if (dimension.depth) arrString.push(`${this.$t('depth')} ${dimension.depth} cm `)
      if (dimension.height) arrString.push(`${this.$t('height')} ${dimension.height} cm `)
      if (dimension.weight) arrString.push(`${this.$t('weight')} ${dimension.weight} kg `)
      return arrString.join(' | ')
    }

    return ''
  }

  goToBarcode(id: string) {
    this.$router.push({ name: 'barcodeView', params: { id } })
  }

  displayUsername(userId?: string) {
    if (userId) {
      const user = this.getUsers.find((item) => item.id === userId)
      return user ? user.name : ''
    } else return ''
  }

  @Watch('id')
  @Watch('path')
  async reloadData() {
    if (!this.id) return
    try {
      this.loading = true
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      this.dataUrl = await QRCode.toDataURL(this.barcodeURL)
      this.loading = false

      if (!this.isLoggedAsClient) {
        await this.loadRecycleHistory()
      }
    } catch (error) {
      errorHandler(error as Error)
    } finally {
      this.loading = false
    }
  }

  async loadRecycleHistory() {
    await getRecycleHistory(this.id, this.recycleHistorySkipItem, 10)
      .then((res) => {
        this.recycleHistoryBarcodes = res
      })
      .catch((err) => {
        console.error('ignore-error', err)
        this.recycleHistoryBarcodes = []
      })
      .finally(() => {
        this.isLoadedRecycleHistory = true
      })
  }

  async onHistorySkipItem(skip: number) {
    this.recycleHistorySkipItem = skip
    this.loading = true
    await this.loadRecycleHistory()
    this.loading = false
  }

  async created() {
    const promises = [this.reloadData()]
    if (!this.$store.state.profile.isLoadedUserList) {
      this.loading = true
      promises.push(this.$store.dispatch(FETCH_USER_LIST).finally(() => (this.loading = false)))
    }

    await Promise.all(promises)
  }

  redirectTo(data: IRedirectData) {
    if (data.path) {
      this.$router.push({
        name: 'barcodeView',
        params: {
          id: data.mainBarcodeId,
        },
        query: {
          path: data.path,
        },
      })
    } else {
      this.goToBarcode(data.mainBarcodeId)
    }
  }

  async downloadCSV(key: string) {
    try {
      let count = 50
      if (key === 'parentId') {
        count = this.barcode.childrenCount
      }
      if (key === 'rootId') {
        count = this.recycleHistoryBarcodes.length
      }
      const payload = {
        condition: {
          projectCode: this.project?.code,
          version: this.project?.version,
          isArchived: false,
          searchConditionBlocks: [
            {
              searchConditions: [
                {
                  key: key,
                  valueType: 'string',
                  value: this.barcode?.[key] || this.barcode?.id,
                },
              ],
              logicalOperator: LOGICAL_OPERATOR_VALUE.and,
            },
          ],
          count,
          skip: 0,
        },
      }
      await exportBarCode((payload as unknown) as Record<string, unknown>)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

:deep() {
  .barcode-container {
    > div {
      width: 100%;
      height: 100%;
    }

    .barcode-icon {
      &__icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.full-page-width {
  width: calc(100% - 320px);
}

.fixed-header-container {
  padding: 40px 40px 0px 40px;
  position: fixed;
  top: 0;
  left: 240px;
  z-index: 9;
  background-color: #efefef;
}

@media (max-width: #{$max-1024-width}) {
  .fixed-table {
    padding-top: 269px;

    .table-actions {
      top: 190px;
    }
  }
}

.el-process-btn {
  &.orange-btn {
    &:hover {
      border-color: lightgoldenrodyellow;
      background-color: #fb923c;
      color: white;
    }
  }

  &.green-btn {
    background-color: #20cf90;
    border-color: #a5ebd2;
    color: white;
  }

  &.is-disabled {
    color: gray;
    background-color: $light-gray !important;
  }
}
</style>
