
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { TABLE_SORT_TYPE } from '@/utils/constants'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeList,
  IBarcodeSearchForm,
  ITableSortData,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, BarcodeIcon },
  emits: ['dialog:close', 'update:selectedBCs'],
  name: 'BarcodeChildren',
})
export default class BarcodeChildren extends Mixins(DataTableMixin) {
  dataSearch = {} as IBarcodeSearchForm

  handleSelectionChange(selections: IBarcode[]) {
    this.$emit(
      'update:selectedBCs',
      selections.map((bc) => bc.id)
    )
  }

  selectableTargetRow(row: IBarcode) {
    return true
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.barcode?.projectDetail?.trackPoints
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.$store.state.barcode?.projectDetail?.barcodeTypes?.[barcodeTypeCode]
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  onEditBarcode(barcode: IBarcodeList) {
    const id = barcode.id
    this.$router.push({
      name: 'barcodeView',
      params: { id },
    })

    this.$emit('dialog:close')
  }

  @Watch('$store.state.barcode?.barcodeChildren', { deep: true })
  barcodeChildrenChanged() {
    this.displayItems = this.$store.state.barcode?.barcodeChildren
  }

  created() {
    this.displayItems = this.$store.state.barcode?.barcodeChildren
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order]
    this.redirectIndex()
    this.sortDisplayItems()
  }
}
