<template>
  <div class="dialog-area">
    <!-- custom-class="dialog-children-barcodes" -->
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="_isShowDialog"
      width="1100px"
      top="0"
      :destroy-on-close="false"
      :close-on-click-modal="false"
      :show-close="true"
      @close="_isShowDialog = false"
    >
      <template #title>
        <div class="flex justify-between items-center">
          <div class="bold">
            {{ $t('barcode.pairing_settings') }}
          </div>
        </div>
      </template>
      <div class="flex justify-evenly">
        <!--=====================  SEARCH FORM ================================= -->
        <el-form class="w-full">
          <div class="flex flex-row mb-2 py-3 px-2 rounded-md bg-white border-solid border border-gray-300">
            <div class="flex-grow">
              <SearchForm
                ref="searchForm"
                :version="projectVersion"
                v-model:searchConditionBlocks="dataSearch.searchConditionBlocks"
                v-model:logicalOperator="dataSearch.logicalOperator"
              />
            </div>
            <div class="flex flex-col items-center justify-center gap-y-3">
              <div class="w-full">
                <el-button
                  type="default"
                  class="bg-blue-dark-min text-white focus:text-white rounded-md w-full"
                  size="medium"
                  @click="performSearch"
                >
                  {{ $t('barcode.search') }}
                </el-button>
              </div>

              <div class="w-full">
                <el-button type="default" class="w-full rounded-md" size="medium" @click="clearSearchForm">
                  {{ $t('clearAll') }}
                </el-button>
              </div>
            </div>
          </div>

          <!-- ============================== SEARCH RESULTS ====================================-->
          <div class="mb-2 py-3 px-2 rounded-md bg-white border-solid border border-gray-300">
            <div>
              <div>
                <div class="my-4 flex-grow">
                  <el-table
                    :data="searchResultBCs"
                    class="rounded-xl w-full"
                    header-cell-class-name="custom-table__header-cell"
                    cell-class-name="custom-table__cell cursor-pointer"
                    border
                    :empty-text="$t('empty')"
                    highlight-current-row
                    @sort-change="sortBarcode"
                    @selection-change="handleSearchSelectionChange"
                  >
                    <el-table-column type="selection" :selectable="selectableSearchRow" :min-width="5" />
                    <el-table-column prop="id" :label="$t('barcode.id')" :min-width="17" />
                    <el-table-column :label="$t('tracking_number')" :min-width="22">
                      <template v-slot="scope">{{ scope.row.activationData.trackingNumber }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('barcode.externalId')" :min-width="15">
                      <template v-slot="scope">{{ scope.row.activationData.externalId }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('projects.barcodeType')" :min-width="20">
                      <template v-slot="scope">
                        <div class="flex" v-if="getBarcodeType(scope.row.barcodeType)">
                          <div class="w-8 h-8 barcode-icon-container flex item-centers">
                            <BarcodeIcon :barcodeType="getBarcodeType(scope.row.barcodeType)" :isShowLabel="false" />
                          </div>
                          <div class="flex-grow flex items-center ml-4">
                            {{ getBarcodeType(scope.row.barcodeType)?.name }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('barcode.currentTrackpoint')" :min-width="20">
                      <template v-slot="scope">{{ getTrackPointName(scope.row.currentTrackPointKey) }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="audit.createdDateTime"
                      :label="$t('barcode.createdDate')"
                      :min-width="20"
                      sortable
                    >
                      <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit?.createdDateTime) }}</template>
                    </el-table-column>
                    <el-table-column prop="isActive" :label="$t('status')" :min-width="10">
                      <template v-slot="scope">{{ scope.row.isActive ? $t('active') : $t('inactive') }}</template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="grid grid-cols-3 gap-2 paging-container">
                  <div></div>
                  <div class="flex justify-center">
                    <el-button
                      type="default"
                      class="bg-blue-dark-min text-white focus:text-white rounded-md"
                      size="medium"
                      @click="onAddTargetList"
                    >
                      {{ $t('barcode.add_to_target_list') }}
                    </el-button>
                  </div>
                  <div class="flex justify-end">
                    <el-pagination
                      layout="prev, pager, next"
                      :total="searchResultCount"
                      :page-size="getBarcodePerPageSize"
                      :pager-count="5"
                      :current-page="currentPageNum"
                      @current-change="currentPageChange($event)"
                      background
                      class="custom-pagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ============================== TARGET RESULTS ====================================-->
          <div class="mt-2 py-3 px-2 rounded-md bg-white border-solid border border-gray-300">
            <div>
              <div class="my-4 flex-grow">
                <el-table
                  :data="selectedPairingBCs"
                  class="rounded-xl w-full"
                  header-cell-class-name="custom-table__header-cell"
                  cell-class-name="custom-table__cell cursor-pointer"
                  border
                  :empty-text="$t('empty')"
                  highlight-current-row
                  @sort-change="sortBarcode"
                  @selection-change="handleTargetSelectionChange"
                >
                  <el-table-column type="selection" :selectable="selectableTargetRow" :min-width="5" />
                  <el-table-column prop="id" :label="$t('barcode.id')" :min-width="17" />
                  <el-table-column :label="$t('tracking_number')" :min-width="22">
                    <template v-slot="scope">{{ scope.row.activationData.trackingNumber }}</template>
                  </el-table-column>
                  <el-table-column :label="$t('barcode.externalId')" :min-width="15">
                    <template v-slot="scope">{{ scope.row.activationData.externalId }}</template>
                  </el-table-column>
                  <el-table-column :label="$t('projects.barcodeType')" :min-width="20">
                    <template v-slot="scope">
                      <div class="flex" v-if="getBarcodeType(scope.row.barcodeType)">
                        <div class="w-8 h-8 barcode-icon-container flex item-centers">
                          <BarcodeIcon :barcodeType="getBarcodeType(scope.row.barcodeType)" :isShowLabel="false" />
                        </div>
                        <div class="flex-grow flex items-center ml-4">
                          {{ getBarcodeType(scope.row.barcodeType)?.name }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('barcode.currentTrackpoint')" :min-width="20">
                    <template v-slot="scope">{{ getTrackPointName(scope.row.currentTrackPointKey) }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="audit.createdDateTime"
                    :label="$t('barcode.createdDate')"
                    :min-width="20"
                    sortable
                  >
                    <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit?.createdDateTime) }}</template>
                  </el-table-column>
                  <el-table-column prop="isActive" :label="$t('status')" :min-width="10">
                    <template v-slot="scope">{{ scope.row.isActive ? $t('active') : $t('inactive') }}</template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="flex justify-center mt-4 paging-container">
                <el-button type="primary" size="medium" @click="onRemoveTargetList">
                  {{ $t('barcode.remove_from_target_list') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="_isShowDialog = false">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="performPairing">
              {{ $t('barcode.pairing') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import { FETCH_BARCODE, FETCH_BARCODE_CHILDREN, SET_PAGE_NOT_FOUND } from '@/store/actions'
import { getBarcodeList, getCountBarcodeList, pairBarcode } from '@/utils/api'
import { removeIdxList, removeIf } from '@/utils/arrayUtils'
import errorHandler from '@/utils/errorHandler'
import { formatSearchConditionBlocks, initDataSearch } from '@/utils/helpers'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeSearch,
  IBarcodeSearchForm,
  ISearchConditionBlock,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, ProvideReactive } from 'vue-property-decorator'
@Options({
  components: { SearchForm, BarcodeIcon },
  name: 'BarcodePairingDialog',
})
export default class BarcodePairingDialog extends Vue {
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  @Prop({ type: String }) readonly barcodeId?: string
  @ProvideReactive() projectVersion = 0

  dataSearch = {} as IBarcodeSearchForm

  loading = false
  currentPageNum = 1
  searchResultBCs = []
  searchResultCount = 0

  selectedPairingBCs = [] as IBarcode[]

  selectableSearchRow(row: IBarcode) {
    return (
      row.isActivated &&
      !row.isDeactivated &&
      row.id !== this.barcode.id &&
      !this.selectedPairingBCs.map((bc) => bc.id).includes(row.id)
    )
  }

  selectableTargetRow(row: IBarcode) {
    return true
  }

  get getBarcodePerPageSize() {
    return Math.round((window.innerHeight - 100) / 100)
  }

  get project() {
    return this.$store.state.barcode?.projectDetail
  }

  previousSearchData = {} as IBarcodeSearch
  previousSearchCount = 0

  get barcode() {
    return this.$store.state.barcode?.barcode
  }

  checkedBCs = [] as IBarcode[]
  handleSearchSelectionChange(selectedSearchBCs: IBarcode[]) {
    this.checkedBCs = selectedSearchBCs
  }

  handleTargetSelectionChange(selectedSearchBCs: IBarcode[]) {
    this.checkedTargetBCs = selectedSearchBCs
  }

  onAddTargetList() {
    const newTargets = [] as IBarcode[]
    const addToTargetList = async () => {
      try {
        this.loading = true
        await pairBarcode({
          isDryRun: true,
          parentBarcodeId: this.barcode.id,
          childrenBarcodeIds: newTargets.map((bc) => bc.id),
          forcePairing: true,
        })
        this.selectedPairingBCs.push(...newTargets)
      } catch (err) {
        errorHandler(err)
      } finally {
        this.loading = false
      }
    }

    const removeList: number[] = []
    this.checkedBCs.forEach((bc, idx) => {
      // local filter
      const found = this.selectedPairingBCs.find((selectedBC) => bc.id === selectedBC.id)
      if (!found) newTargets.push(bc)

      removeIf<IBarcode>(this.searchResultBCs, (item) => item.id === bc.id)
      removeList.push(idx)
    })
    // remove check in search list
    removeIdxList(this.checkedBCs, removeList)

    // Do pairing new targets
    addToTargetList()
  }

  checkedTargetBCs = [] as IBarcode[]
  onSelectedTargetBC(checked: boolean, bc: IBarcode) {
    if (checked) {
      this.checkedTargetBCs.push(bc)
    } else {
      this.checkedTargetBCs.splice(this.checkedTargetBCs.indexOf(bc), 1)
    }
  }

  onRemoveTargetList() {
    this.checkedTargetBCs.forEach((bc, idx) => {
      removeIf<IBarcode>(this.selectedPairingBCs, (item) => bc.id === item.id)
      this.checkedTargetBCs.splice(1, idx)
    })
  }

  async performSearch() {
    this.checkedBCs = []
    this.dataSearch.skip = 0
    this.dataSearch.projectCode = this.project.code
    this.dataSearch.version = this.project.version

    this.fetchSearchBCs()
  }

  clearSearchForm() {
    this.dataSearch = initDataSearch()
  }

  get path() {
    return this.$route.query?.path
  }

  async performPairing() {
    try {
      this.loading = true
      await pairBarcode({
        isDryRun: false,
        parentBarcodeId: this.barcode.id,
        childrenBarcodeIds: this.selectedPairingBCs.map((bc) => bc.id),
        forcePairing: true,
      })
      openMessage(this.$t('barcode.barcode_paired'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this._isShowDialog = false
      this.selectedPairingBCs = []
      this.checkedBCs = []
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.barcode.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))
      this.loading = false
    }
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.$store.state.barcode?.projectDetail?.barcodeTypes?.[barcodeTypeCode]
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.barcode?.projectDetail?.trackPoints
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  async fetchSearchBCs() {
    try {
      this.loading = true
      const data = this.dataSearch

      // format dataSearch
      const condBlocks = [] as ISearchConditionBlock[]
      data.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))
      const dataSearch = {
        condition: {
          ...data,
          searchConditionBlocks: formatSearchConditionBlocks(condBlocks),
        },
      } as IBarcodeSearch

      try {
        this.searchResultBCs = await getBarcodeList(dataSearch)
        const isPerformCount =
          isEmpty(this.previousSearchData) ||
          !isEqual(omit(cloneDeep(this.previousSearchData), 'skip'), omit(data, 'skip'))
        if (isPerformCount) {
          getCountBarcodeList(dataSearch)
            .then((res) => {
              this.searchResultCount = res?.count ?? 0
              this.previousSearchCount = this.searchResultCount
            })
            .catch((err) => console.error('error', err))
        } else {
          this.searchResultCount = this.previousSearchCount ?? dataSearch.condition.count
        }
      } catch (e) {
        this.searchResultBCs = []
        this.searchResultCount = 0
      }
    } catch (err) {
      errorHandler(err)
    } finally {
      this.loading = false
    }
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    // this.currentSkip = skipNum

    // if (this.isTriggerPageChanging) {
    this.currentPageNum = pageNum
    this.dataSearch.skip = (Number(pageNum) - 1) * Number(this.dataSearch.count)
    await this.fetchSearchBCs()
  }

  sortBarcode() {
    // Sort
  }

  mounted() {
    this.dataSearch = initDataSearch()
    this.projectVersion = this.project.version
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1100px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
