
import SearchItem from '@/components/barcodeSearch/SearchItem.vue'
import { BARCODE_SEARCH_KEYS, CUSTOM_FIELD_KEY, LOGICAL_OPERATOR_VALUE } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  IBarcodeDefinitionType,
  ICustomFieldData,
  ISearchConditionBlock,
  TArrProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { SearchItem },
  emits: ['update:searchConditionBlocks', 'update:logicalOperator'],
  name: 'SearchForm',
})
export default class SearchForm extends Vue {
  @PropSync('searchConditionBlocks', { type: Object }) _searchConditionBlocks!: ISearchConditionBlock[]
  @PropSync('logicalOperator', { type: String }) _logicalOperator!: string
  @Prop({ type: Number }) readonly version!: number

  created() {
    this.conditionBlocks = [...this._searchConditionBlocks.map((b) => ({ ...b }))]
  }

  // Temporary fix when server return 'And' 'Or' instead correct value 'and' 'or'
  get operators() {
    return this._logicalOperator === 'And' || this._logicalOperator === 'Or'
      ? { and: 'And', or: 'Or' }
      : { and: 'and', or: 'or' }
  }

  DEFAULT_CONDITION_BLOCK = {
    searchConditions: [
      {
        key: '',
        valueType: '',
      },
    ],
    logicalOperator: LOGICAL_OPERATOR_VALUE.and,
  }

  conditionBlocks: ISearchConditionBlock[] = [cloneDeep(this.DEFAULT_CONDITION_BLOCK)]

  @Watch('_searchConditionBlocks', { deep: true })
  onModelConditionBlocksChanged() {
    if (!isEqual(this.conditionBlocks, this._searchConditionBlocks)) {
      this.conditionBlocks = [...this._searchConditionBlocks.map((b) => cloneDeep(b))]
    }
  }

  @Watch('conditionBlocks', { deep: true })
  onConditionBlocksChanged() {
    if (!isEqual(this.conditionBlocks, this._searchConditionBlocks)) {
      this.$emit('update:searchConditionBlocks', cloneDeep(this.conditionBlocks))
    }
  }

  isAndOperator(logicalOperatorValue: string | undefined) {
    return logicalOperatorValue === LOGICAL_OPERATOR_VALUE.and
  }

  projectCustomFields(blockIdx: number): TArrProjectCustomField[] {
    if (this.version === 0) return []

    const projectBarcodeTypes = (this.$store.state.barcode.projectList.barcodeTypes ||
      this.$store.state.barcode.projectDetail.barcodeTypes ||
      {}) as Record<string, IBarcodeDefinitionType>

    const currentBlock = this.conditionBlocks[blockIdx]

    const searchConditionBCTypeKeys = currentBlock.searchConditions.reduce(
      (total, i) => (i.key === 'barcodeType' && i.value ? [...total, i.value] : total),
      [] as string[]
    )

    const availableBcTypes = Object.entries(projectBarcodeTypes).filter((item) => {
      if ((searchConditionBCTypeKeys ?? []).length !== 0 && this.isAndOperator(currentBlock.logicalOperator)) {
        return searchConditionBCTypeKeys.includes(item[0])
      }
      return true
    })

    return (availableBcTypes || []).reduce(
      (customFields, bcType) => [
        ...customFields,
        ...this.getSearchCustomFields(bcType[0], bcType[1].activationFields.customFields),
      ],
      [] as TArrProjectCustomField[]
    )
  }

  addSearchConditionBlock() {
    this.conditionBlocks.push(cloneDeep(this.DEFAULT_CONDITION_BLOCK))
  }

  removeSearchConditionBlock(idx: number) {
    this.conditionBlocks.splice(idx, 1)
  }

  getSearchCustomFields(bcTypeKey: string, data?: Record<string, ICustomFieldData>): TArrProjectCustomField[] {
    const customFieldSearchKeys = [
      CUSTOM_FIELD_KEY.text,
      CUSTOM_FIELD_KEY.number,
      CUSTOM_FIELD_KEY.singleSelect,
      CUSTOM_FIELD_KEY.multiSelect,
      CUSTOM_FIELD_KEY.date,
      CUSTOM_FIELD_KEY.email,
      CUSTOM_FIELD_KEY.phoneNumber,
    ]
    const result = [] as TArrProjectCustomField[]
    for (const key in data) {
      if (customFieldSearchKeys.includes(data[key]?.fieldType)) {
        result.push({ ...data[key], customFieldKey: key, bcTypeKey } as TArrProjectCustomField)
      }
    }
    return result
  }

  removeItem(blockIdx: number, conditionIdx: number) {
    const block = this.conditionBlocks[blockIdx]
    const hasLogicalOperator = (block.searchConditions ?? []).length > 1
    if (hasLogicalOperator) {
      block.searchConditions.splice(conditionIdx, 1)
      if (block.searchConditions.length === 1) block.logicalOperator = LOGICAL_OPERATOR_VALUE.and
    }
  }

  addItem(blockIdx: number) {
    this.conditionBlocks[blockIdx].searchConditions.push({ key: '', valueType: '' })
  }

  onUpdateSearchConditions(blockIdx: number) {
    const block = this.conditionBlocks[blockIdx]
    const hasBCTypeField = !!block.searchConditions.map((i) => i.key).find((i) => i === 'barcodeType')
    if (this.isAndOperator(block.logicalOperator) && hasBCTypeField) {
      const availableCFs = this.projectCustomFields(blockIdx).map((i) => i.customFieldKey)
      for (let i = (block.searchConditions ?? []).length - 1; i >= 0; i--) {
        if (
          block.searchConditions[i].key === BARCODE_SEARCH_KEYS.customField &&
          !availableCFs.includes(block.searchConditions[i].subKey ?? '')
        ) {
          block.searchConditions.splice(i, 1)
        }
      }
    }
  }
}
