
import BarcodeChildren from '@/components/barcode/BarcodeChildren.vue'
import BarcodeCustomField from '@/components/barcode/BarcodeCustomField.vue'
import BarcodeRow from '@/components/barcode/BarcodeRow.vue'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconUpload from '@/components/svg/IconUpload.vue'
import {
  FETCH_BARCODE,
  FETCH_BARCODE_CHILDREN,
  FETCH_USER_LIST,
  SET_HAVE_NEW_EXPORT_RECORD,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import {
  exportBarCode,
  getBarcodeLedgerStatus,
  getRecycleHistory,
  invokeExternalAPI,
  reconcileBarcode,
  requestBarcodeReport,
  unpairBarcode,
  updateReferenceFieldBarcode,
} from '@/utils/api'
import { LOGICAL_OPERATOR_VALUE } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { displayDateTimeLocal, displayMomentAtParam } from '@/utils/timeUtils'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import QRCode from 'qrcode'
import TraceView from 'smartbarcode-web-core/src/lib-components/tracing.vue'
import { ECustomFieldType, EExportType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IBarcodeReportTemplate,
  IDimension,
  IRedirectData,
  ISelectData,
  ITracingMessage,
  ITrackingData,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import BarcodeHistoryDialog from './BarcodeHistoryDialog.vue'
import BarcodePairingDialog from './BarcodePairingDialog.vue'
@Options({
  components: {
    BarcodeRow,
    BarcodeIcon,
    BarcodeCustomField,
    BarcodePairingDialog,
    PageHeader,
    TraceView,
    BarcodeChildren,
    ItemNotFound,
    BarcodeHistoryDialog,
    IconDownload,
    IconUpload,
  },
  name: 'BarcodeDetail',
})
export default class BarcodeDetail extends Vue {
  isShowChildrenDialog = false
  isShowHistoryBarcodeDialog = false
  dataUrl = ''
  recycleHistoryBarcodes = [] as IBarcode[]
  loading = false
  isLoadedRecycleHistory = false
  operationTypes = ''
  recycleHistorySkipItem = 0

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get recycleCount() {
    return this.barcode.recycleCount || 0
  }

  openBarcodeHistoryDialog() {
    this.isShowHistoryBarcodeDialog = true
  }

  unpairingSelectedBCs: string[] = []
  onUpdateUnpairingBCs(bcList: string[]) {
    this.unpairingSelectedBCs = bcList
  }

  async performUnpairing() {
    try {
      this.loading = true
      const req = {
        isDryRun: true,
        parentBarcodeId: this.barcode.id,
        isUnpairAll: false,
        unpairChildrenIds: this.unpairingSelectedBCs,
      }
      await unpairBarcode(req)

      req.isDryRun = false
      await unpairBarcode(req)
      openMessage(this.$t('barcode.barcode_unpaired'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this.unpairingSelectedBCs = []
      this.isShowChildrenDialog = false
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      this.loading = true
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))

      this.loading = false
    }
  }

  isShowPairingBCDialog = false
  onOpenPairingDialog() {
    this.isShowPairingBCDialog = true
  }

  get isAllowPairing() {
    return (
      !this.barcode?.isDeactivated &&
      (this.barcodeType?.pairing?.[this.barcode.currentTrackpointKey ?? 0]?.isAvailable ?? false)
    )
  }

  get messages(): ITracingMessage {
    return {
      trackingNumber: this.$t('tracking_number'),
      undoTrackingData: this.$t('barcode.undo_tracking_data'),
      noTrackingHistory: this.$t('barcode.no_tracking_history'),
      packageImages: this.$t('barcode.packageImages', { number: '{0}' }),
      confirmUndoTrackingData: this.$t('barcode.confirm_undo_tracking_data'),
      cancel: this.$t('cancel'),
      ok: this.$t('ok'),
      trackedDatetime: this.$t('barcode.tracked_datetime'),
      trackedUsername: this.$t('barcode.tracked_username'),
      sign: this.$t('sign'),
    } as ITracingMessage
  }

  get getExternalWebAPIButtonLabel() {
    const barcodeType: IBarcodeDefinitionType = this.barcodeType
    return barcodeType?.externalWebAPIIntegration?.buttonLabel || this.$t('export_pdf')
  }

  get externalWebAPIAvailable() {
    return !!this.barcodeType?.externalWebAPIIntegration
  }

  get referenceAvailable() {
    const bc = cloneDeep(this.barcode?.activationData?.customFields) || {}
    const isActivationDataReferenceAvailable = Object.keys(bc).some((val) => {
      if (bc?.[val]?.fieldType) return bc[val].fieldType === ECustomFieldType.REFERENCE
    })

    const trackingData = this.barcode.trackingData as ITrackingData[]
    const isTrackingDataReferenceAvailable = trackingData?.some((td) => {
      const isTrackingDataReferenceAvailable = Object.keys(td?.customFields || {}).some((key) => {
        if (td?.customFields?.[key]?.fieldType) return td?.customFields?.[key]?.fieldType === ECustomFieldType.REFERENCE
      })
      return isTrackingDataReferenceAvailable
    })

    return isActivationDataReferenceAvailable || isTrackingDataReferenceAvailable
  }

  get barcodeReportTemplates() {
    return this.barcodeType?.barcodeReportTemplates ?? ([] as IBarcodeReportTemplate[])
  }

  get id() {
    return this.$route.name === 'barcodeView' ? this.$route.params.id : ''
  }

  get path() {
    return this.$route.query?.path
  }

  get isAvailableRecycledBarcode() {
    return this.barcode.id !== this.barcode.rootId
  }

  get project() {
    return this.$store.state.barcode?.projectDetail
  }

  get barcode() {
    return this.$store.state.barcode?.barcode
  }

  get product() {
    return this.$store.state.barcode?.product
  }

  get barcodeType(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes?.[this.barcode.barcodeType]
  }

  get projectBarcodeTypeKey() {
    return this.project?.barcodeTypes
  }

  get activationData() {
    return this.$store.state.barcode?.barcode?.activationData
  }

  get currentTrackPoint() {
    return this.project?.trackPoints?.[this.barcode?.currentTrackPointKey]?.name
  }

  get barcodeURL() {
    const appHost = process.env.VUE_APP_MOBILE_BASE_URL
    return this.barcode ? `${appHost}/${this.$store.getters.projectParam}/qrcode/${this.barcode.id}` : ''
  }

  get hasFirstLinkingBarcodes(): boolean {
    return !isEmpty(this.barcode.activationData?.linkedBarcodes)
  }

  get hasTrackingData() {
    return !isEmpty(this.barcode?.trackingData)
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  async onRefreshReferenceFieldClick() {
    try {
      this.loading = true
      await updateReferenceFieldBarcode(this.barcode.id)
      await this.reloadData()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async openChildrenBarcode() {
    if (this.barcode.id !== this.$store.state.barcode?.parentId) {
      this.loading = true
      await this.$store.dispatch(FETCH_BARCODE_CHILDREN, this.barcode.id).finally(() => (this.loading = false))
    }
    this.isShowChildrenDialog = true
  }

  get getUsers(): ISelectData[] {
    return this.$store.state.profile?.userList
  }

  async InvokeExternalWebAPI() {
    try {
      this.loading = true
      const response = await invokeExternalAPI(this.barcode?.id || '')
      if (!response) return

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${displayMomentAtParam(moment())}.pdf`
      document.body.appendChild(link)
      link.click()
      openMessage(this.$t('barcode_status.completed'), 'success')
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async requestReport(code: string) {
    try {
      this.loading = true
      if (this.barcode?.id) {
        await requestBarcodeReport(this.barcode.id, code)
      }

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async invokeConcile(index: number) {
    try {
      const { trackingData } = await reconcileBarcode(this.barcode?.id, index)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[
        index
      ].lastLedgerCertifiedDateTime = lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  async getLedgerStatus(index: number) {
    try {
      const { trackingData } = await getBarcodeLedgerStatus(this.barcode?.id, index)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLoading = false

      this.$refs.tracingComponent.trackingDataItemsLoading[
        index
      ].lastLedgerCertifiedDateTime = lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  closeChildrenDialog() {
    this.isShowChildrenDialog = false
  }

  formatDimension(dimension: IDimension) {
    if (dimension) {
      const arrString = []
      if (dimension.width) arrString.push(`${this.$t('width')} ${dimension.width} cm `)
      if (dimension.depth) arrString.push(`${this.$t('depth')} ${dimension.depth} cm `)
      if (dimension.height) arrString.push(`${this.$t('height')} ${dimension.height} cm `)
      if (dimension.weight) arrString.push(`${this.$t('weight')} ${dimension.weight} kg `)
      return arrString.join(' | ')
    }

    return ''
  }

  goToBarcode(id: string) {
    this.$router.push({ name: 'barcodeView', params: { id } })
  }

  displayUsername(userId?: string) {
    if (userId) {
      const user = this.getUsers.find((item) => item.id === userId)
      return user ? user.name : ''
    } else return ''
  }

  @Watch('id')
  @Watch('path')
  async reloadData() {
    if (!this.id) return
    try {
      this.loading = true
      await this.$store
        .dispatch(FETCH_BARCODE, { id: this.id, path: this.path })
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode.barcode' }))
      this.dataUrl = await QRCode.toDataURL(this.barcodeURL)
      this.loading = false

      if (!this.isLoggedAsClient) {
        await this.loadRecycleHistory()
      }
    } catch (error) {
      errorHandler(error as Error)
    } finally {
      this.loading = false
    }
  }

  async loadRecycleHistory() {
    await getRecycleHistory(this.id, this.recycleHistorySkipItem, 10)
      .then((res) => {
        this.recycleHistoryBarcodes = res
      })
      .catch((err) => {
        console.error('ignore-error', err)
        this.recycleHistoryBarcodes = []
      })
      .finally(() => {
        this.isLoadedRecycleHistory = true
      })
  }

  async onHistorySkipItem(skip: number) {
    this.recycleHistorySkipItem = skip
    this.loading = true
    await this.loadRecycleHistory()
    this.loading = false
  }

  async created() {
    const promises = [this.reloadData()]
    if (!this.$store.state.profile.isLoadedUserList) {
      this.loading = true
      promises.push(this.$store.dispatch(FETCH_USER_LIST).finally(() => (this.loading = false)))
    }

    await Promise.all(promises)
  }

  redirectTo(data: IRedirectData) {
    if (data.path) {
      this.$router.push({
        name: 'barcodeView',
        params: {
          id: data.mainBarcodeId,
        },
        query: {
          path: data.path,
        },
      })
    } else {
      this.goToBarcode(data.mainBarcodeId)
    }
  }

  async downloadCSV(key: string) {
    try {
      let count = 50
      if (key === 'parentId') {
        count = this.barcode.childrenCount
      }
      if (key === 'rootId') {
        count = this.recycleHistoryBarcodes.length
      }
      const payload = {
        condition: {
          projectCode: this.project?.code,
          version: this.project?.version,
          isArchived: false,
          searchConditionBlocks: [
            {
              searchConditions: [
                {
                  key: key,
                  valueType: 'string',
                  value: this.barcode?.[key] || this.barcode?.id,
                },
              ],
              logicalOperator: LOGICAL_OPERATOR_VALUE.and,
            },
          ],
          count,
          skip: 0,
        },
      }
      await exportBarCode((payload as unknown) as Record<string, unknown>)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }
}
